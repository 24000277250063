import "./about.css";
import ME from "../../assets/me-about.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        {/* <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div> */}

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>7+ Years Working</small>
            </article>

            <article className="about__card">
              <FiUsers className="about_icon" />
              <h5>Clients/Companies</h5>
              <small>7</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about_icon" />
              <h5>Projects</h5>
              <small>10+</small>
            </article>
          </div>

          <p>
            Backend Software Developer with frontend experience and Support
            Engineer with more than 7 years of software engineering experience
            in the development of commercial apps. Good knowledge of C#, .NET,
            ASP.NET MVC. Solid knowledge of frontend technologies such as
            JavaScript/React, TypeScript HTML, CSS. Has experience in designing
            databases and queries using MSSQL, MySQL. Able to develop,
            troubleshoot, debug, and maintain complex applications. Hands-on
            experience in Agile software lifecycle. Unit testing.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
